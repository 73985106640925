<template>
  <div class="village">
    village page
  </div>
</template>

<script>

export default {
  name: 'village'
}
</script>

<style lang="scss" scoped>
@import 'index.scss';
</style>
